import { saveBData } from "./storage";

export function saveUserSort(userId, users, STORAGE_KEY) {
    try {
        const find = users.find(user => user.id == userId)
        if (find) {
            find.count = find.count + 1;
        } else {
            users.push({
                id: userId,
                count: 1
            });
        }
        saveBData(users, STORAGE_KEY);
    } catch (error) {
        console.error(error);
    }
}



export function saveUserSortSimple(userId, userIds, STORAGE_KEY) {
    try {
        const index = userIds.findIndex(userIdsItem => userIdsItem === userId);
        if (index !== -1) {
            userIds.splice(index, 1); // 删除原有数据
        }
        userIds.push(userId);

        saveBData(userIds, STORAGE_KEY);
    } catch (error) {
        console.error(error);
    }

}